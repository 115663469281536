










import Vue from 'vue';
import Metric from '@/models/Metric'
// components
import MetricView from '@/components/boards/MetricView.vue';

export interface Widget {
    title: String;
    metrics: Metric[];
}

export default Vue.extend({
    name: 'MetricsPanel',
    components: {
        MetricView
    },
    props: {
        widgets: { type: Array as () => Widget[], required: true }
    },
    computed: {
        columnWidth(): string {
            const len = 12/this.widgets.length;
            if(len == 4 || len == 6 || len == 3) {
                return `col-md-${len}`;
            } else {
                return 'col-md-9'
            }
        }
    }
})
