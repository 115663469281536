import { NullableString, UUID } from "@/models/Types";
import { PrivilegeRequest } from "@/models/Privileges";

export default interface Invite {
    //sponsor fields
    sponsorId: UUID;
    sponsorOrg: string;
    //invitee fields
    clientName: NullableString;
    recipient: NullableString; 
    firstName: NullableString; 
    lastName: NullableString;
    role: PrivilegeRequest | null;
    // org fields
    isNewClient: boolean;
    isUnlimited: boolean;
    isChannel: boolean;
    isInvoiced: boolean;
}

export const DefaultInvite: Invite = {
    sponsorId: '',
    sponsorOrg: '',
    clientName: null,
    recipient: null,
    firstName: null,
    lastName: null,
    role: null,
    isNewClient: false,
    isUnlimited: false,
    isChannel: false,
    isInvoiced: false
}
