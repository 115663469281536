



















import Vue from 'vue'

interface Data {
    exp: Date
    hover: boolean
}

export default Vue.extend({
    name: 'AdAgingBar',
    props: {
        expiration: { type: String, required: true }
    },
    data(): Data {
        return {
            exp: new Date(this.expiration),
            hover: false
        }
    },
    computed: {
        label(): string {
            return this.daysTillExpiration.toFixed(0) == '1' ? `1 Day Remaining` : `${this.daysTillExpiration.toFixed(0)} Days Remaining`
        },
        daysTillExpiration(): number {
            return (this.exp.getTime() - new Date().getTime())/(1000 * 60 * 60 * 24) + 1;
        },
        color(): string {
            if(this.daysTillExpiration < 3) {
                return 'danger';
            } else if(this.daysTillExpiration < 6) {
                return 'warning';
            } else {
                return 'success';
            }
        }
    }
})
