


































































































































import { UUID } from '@/models/Types';
import Vue from 'vue';
import api from '@/api';
import CompanyAbstract from '@/models/CompanyAbstract';
import CandidateStat from '@/models/CandidateStat';
import JobAbstract from '@/models/JobAbstract';
import Metric from '@/models/Metric';
import UserSession, { EmptySession } from '@/models/UserSession';
import { EffectivePrivileges } from '@/models/Privileges';
import  { JobPermissions, ProfilePermissions, SystemPermissions } from '@/models/Permissions';
//components
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialSelect from '@/components/MaterialSelect.vue';
import ClientInviteModal from '@/components/modals/ClientInviteModal.vue';
import PaymentLockoutModal from '@/components/modals/PaymentLockout.vue';
import PaymentMethodExpiredModal from '@/components/modals/PaymentMethodExpired.vue';
import JobCardList from '@/components/boards/JobCardList.vue';
import ClientSidebar from '@/components/boards/ClientSidebar.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import MetricsPanel, { Widget } from '@/components/boards/MetricsPanel.vue';
import {
  // metricGreen,
  // metricBlue,
  // metricYellow,
  metricLightGray,
  metricForest,
  metricOcean,
  metricSlate,
  metricBerry,
  // metricObsidian,
} from '@/components/boards/MetricView.vue';

interface JobCounts {
  drafts: number;
  rightads: number;
  open: number;
  closed: number;
}

interface Filter {
  id: number;
  name: string;
}

interface SessionVM extends UserSession {
  payload: CompanyAbstract;
}

interface Data {
  org: CompanyAbstract | null;
  clients: CompanyAbstract[];
  jobs: JobAbstract[];
  filters: Filter[];
  nameFilter: string;
  catFilter: string;
  aggregateCandidateStats: CandidateStat[];
  loadClients: boolean;
  loadJobs: boolean;
  jcount: JobCounts;
}

export default Vue.extend({
  name: 'NavBoard',
  components: {
    MaterialInput,
    MaterialSelect,
    JobCardList,
    ClientSidebar,
    MetricsPanel,
    ClientInviteModal,
    PaymentLockoutModal,
    BreadCrumb,
    PaymentMethodExpiredModal
  },
  data(): Data {
    return {
      org: null,
      clients: [],
      jobs: [],
      filters: [
        { id: 1, name: 'Open: RightAd' },
        { id: 2, name: 'Open: Career Page' },
        { id: 3, name: 'New Candidates' },
        { id: 4, name: 'Phone Screens' },
        { id: 5, name: 'Interviews' },
        { id: 6, name: 'Offers' },
        { id: 7, name: 'Candidate Count' },
      ],
      nameFilter: '',
      catFilter: '',
      aggregateCandidateStats: [],
      loadClients: false,
      loadJobs: false,
      jcount: {
        drafts: 0,
        rightads: 0,
        open: 0,
        closed: 0
      }
    };
  },
  methods: {
    async loadProfile(pid: UUID) {

      this.$store.commit('setIsLoading', true);

      let session: UserSession = EmptySession;
      this.nameFilter = '';

      try {
        // get the profile
        const rslt = await this.$http.get(`${api}/profiles/${pid}`);
        const rspn: SessionVM = rslt.data;
        this.org = rspn.payload;

        // this.org.expireCount = 30;

        session = {
          token: rspn.token,
          primaryOrg: rspn.primaryOrg,
          currentOrg: rspn.currentOrg,
          currentPrivileges: rspn.currentPrivileges,
        };

        this.$store.commit('setUserSession', session);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }

      try {
        // get candidate status counts for hierarchy
        const rslt = await this.$http.get(
          `${api}/profiles/${pid}/candidate-stats`
        );
        this.aggregateCandidateStats = rslt.data;
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }

      this.$store.commit('setIsLoading', false);

      try {

        this.loadClients = true;
        // get any children
        const rslt = await this.$http.get(`${api}/profiles/${pid}/clients`);
        this.clients = rslt.data;
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      } finally {
        this.loadClients = false;
      }

      // try {
      //   this.loadJobs = true;
      //   // get jobs for entire hierarchy
      //   const rslt = await this.$http.get(`${api}/profiles/${pid}/jobs?status=closed`);
      //   this.jobs = rslt.data;
      // } catch (error) {
      //   // eslint-disable-next-line
      //   console.error(error);
      // } finally {
      //   this.loadJobs = false;
      // }

      // load filters from vuex
      this.catFilter = this.$store.getters.filters.category;

      // payment modals
      if (this.org &&
        !this.org.isInvoiced &&
        !this.org.hasPayment &&
        session.currentPrivileges.sys == SystemPermissions.None
      ) {
        this.$bvModal.show('paymentlock-modal');
      } 
      else if (this.org && this.org.expireDays != null) {
        this.$bvModal.show('payment-method-expired-modal')
      }
      // this.$store.commit('setIsLoading', false);
    },
    showInvite() {
      this.$bvModal.show('invite-modal');
    },
    createClient() {
      this.$router.push({
        name: 'CreateChildProfile',
        params: { cid: this.org!.id },
      });
    },
    createJob() {
      this.$router.push({ name: 'CreateJob', params: { pid: this.org!.id } });
    },
    onCategoryFilter() {
      this.$store.commit('setCategoryFilter', this.catFilter);
    },
    onActives(a: JobAbstract[]) {
      this.jcount.rightads = a.filter(a => (a.status & 2) != 0).length;
      this.jcount.open = a.length - this.jcount.rightads;
    },
    onDrafts(d: JobAbstract[]) {
      this.jcount.drafts = d.length;
    },
    onClosed(c: JobAbstract[]) {
      this.jcount.closed = c.length;
    }
  },
  computed: {
    currentRoute(): UUID {
      return this.$route.params.pid as UUID;
    },
    currentNameFilter(): string {
      return this.nameFilter;
    },
    jobsEndpoint(): string {
      return `${api}/profiles/${this.currentRoute}/jobs`
    },
    isLimited(): boolean {
      // limit if null, else return isLimited status
      return this.org === null ? true : this.org.isLimited;
    },
    createClientDisabled(): boolean {
      return (
        this.isLimited ||
        (this.privileges.p & ProfilePermissions.ManageUsers) ==
          ProfilePermissions.None
      );
    },
    inviteDisabled(): boolean {
      return (
        this.isLimited ||
        (this.privileges.p & ProfilePermissions.InviteClient) ==
          ProfilePermissions.None
      );
    },
    createJobDisabled(): boolean {
      return (this.privileges.j & JobPermissions.Create) == JobPermissions.None;
    },
    privileges(): EffectivePrivileges {
      return this.$store.getters.privileges;
    },
    hasClients(): boolean {
      return this.clients.length > 0;
    },
    // actives(): JobAbstract[] {
    //   return this.jobs.filter((j) => j.status != 0 && (j.status & 4) == 0); // j.status & 155
    // },
    // drafts(): JobAbstract[] {
    //   return this.jobs.filter((j) => j.status == 0);
    // },
    // closed(): JobAbstract[] {
    //   return this.jobs.filter((j) => j.status & 4);
    // },
    jobMetrics(): Metric[] {
      return [
        {
          name: 'Drafts',
          value: this.jcount.drafts,
          color: metricSlate,
        },
        {
          name: 'Career Page',
          value: this.jcount.open,
          color: metricOcean,
        },
        {
          name: 'RightAd',
          value: this.jcount.rightads,
          color: metricForest,
        },
        {
          name: 'Closed',
          value: this.jcount.closed,
          color: metricLightGray,
        },
      ];
    },
    candidateMetrics(): Metric[] {
      const cmetric: Metric[] = [
        { name: 'Unread', value: 0, color: metricLightGray },
        { name: 'Read', value: 0, color: metricSlate },
        { name: 'Evaluating', value: 0, color: metricOcean },
        { name: 'Offered', value: 0, color: metricBerry },
        { name: 'Hired', value: 0, color: metricForest },
        // { name: "Not Hired", value: 0, color: metricSlate },
      ];

      this.aggregateCandidateStats
        .filter((c) => c.status == 0)
        .forEach((s) => (cmetric[0].value += s.count)); // aggregate new
      this.aggregateCandidateStats
        .filter((c) => c.status == 1)
        .forEach((s) => (cmetric[1].value += s.count)); // aggregate new
      this.aggregateCandidateStats
        .filter((c) => c.status & 16)
        .forEach((s) => (cmetric[2].value += s.count)); // aggregate evals
      this.aggregateCandidateStats
        .filter((c) => c.status & 32)
        .forEach((s) => (cmetric[3].value += s.count)); // aggregate offers
      this.aggregateCandidateStats
        .filter((c) => c.status & 64)
        .forEach((s) => (cmetric[4].value += s.count)); // aggregate hires

      return cmetric;
    },
    clientMetrics(): Metric[] {
      return [
        {
          name: 'Active',
          value: this.clients.filter((c) => c.isActive).length,
          color: metricForest,
        },
        {
          name: 'Inactive',
          value: this.clients.filter((c) => !c.isActive).length,
          color: metricLightGray,
        },
      ];
    },
    metricSet(): Widget[] {
      return this.hasClients
        ? [
            { title: 'Jobs', metrics: this.jobMetrics },
            { title: 'Candidates', metrics: this.candidateMetrics },
            { title: 'Clients', metrics: this.clientMetrics },
          ]
        : [
            { title: 'Jobs', metrics: this.jobMetrics },
            { title: 'Candidates', metrics: this.candidateMetrics },
          ];
    },
  },
  watch: {
    currentRoute(pid: UUID) {
      this.loadProfile(pid);
    },
  },
  mounted() {
    // this.$store.commit('setIsLoading', true);
    this.loadProfile(this.currentRoute);
  },
});
