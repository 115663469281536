




































































import Vue from "vue";
import Metric from "@/models/Metric";

// export const metricGreen = '#4da039'
// export const metricBlue = '#2983b1'

export const metricYellow = "#ffbf00";
export const metricForest = "#3DAE2B";
export const metricOcean = "#008FBE";
export const metricSlate = "#898A8D";
export const metricLightGray = "#E7E8E8";
export const metricBerry = "#270089";
export const metricObsidian = "#1C1F2A";

interface Arc {
  arc: string;
  color: string;
}

export default Vue.extend({
  props: {
    title: String,
    // An array of Metric instances, in order of display (which is why it can't be an Object)
    metrics: Array as () => Metric[],
  },
  computed: {
    totalMetric(): Metric {
      const metrics = this.metrics;
      // if (metrics && Array.isArray(metrics) && metrics.length > 0) {
        const total = metrics.map((m) => m.value).reduce((a, b) => a + b, 0);
        let totalMetric = metrics.find((m) => m.value === total);
        if (totalMetric === undefined) {
          totalMetric = {
            name: 'Total',
            value: total,
            color: 'lightgray'
          }
        }
        return totalMetric;
      // }
      // return [];
    },
    arcs(): Arc[] {
      const total = this.totalMetric.value;
      let startAngle = 0;
      let endAngle = 0;
      const arcs: Arc[] = [];
      if (
        this.metrics &&
        Array.isArray(this.metrics) &&
        this.metrics.length > 0
      ) {
        for (let metric of this.metrics) {
          const value = metric.value || 0;
          if (value === 0) continue;
          endAngle = startAngle + 360.0 * (value / total);
          arcs.push({
            arc: this.describeArc(50, 50, 35, startAngle, endAngle),
            color: metric.color,
          });
          startAngle = endAngle;
        }
      }
      return arcs;
    },
  },
  methods: {
    // https://stackoverflow.com/a/18473154/27779
    polarToCartesian(
      centerX: number,
      centerY: number,
      radius: number,
      angleInDegrees: number
    ): { x: number; y: number } {
      const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      };
    },
    // https://stackoverflow.com/a/18473154/27779
    describeArc(
      x: number,
      y: number,
      radius: number,
      startAngle: number,
      endAngle: number
    ): string {
      const start = this.polarToCartesian(x, y, radius, endAngle);
      const end = this.polarToCartesian(x, y, radius, startAngle);
      const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
      return [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y,
      ].join(" ");
    },
  },
});
