

























































import Vue from 'vue';
import CompanyAbstract from '@/models/CompanyAbstract';
import { ProfilePermissions } from '@/models/Permissions';
import { EffectivePrivileges } from '@/models/Privileges';

export default Vue.extend({
  name: 'PaymentMethodExpiredModal',
  props: {
    org: { type: Object as () => CompanyAbstract, required: true },
    privileges: { type: Object as () => EffectivePrivileges, required: true },
  },
  computed: {
    isPaymentAdmin(): boolean {
      return (this.privileges.p & ProfilePermissions.ManagePayments) != 0;
    },
    isUserAdmin(): boolean {
      return (this.privileges.p & ProfilePermissions.ManageUsers) != 0;
    },
  },
  methods: {
    editProfile() {
      if (this.isPaymentAdmin) {
        this.$router.push({
          path: `/profile/${this.org.id}/edit`,
          hash: '#pmt',
        }); // jump to profile edit at anchor '#pmt'
      } else if (this.isUserAdmin) {
        sessionStorage.setItem('org', JSON.stringify(this.org));
        this.$router.push({ name: 'AddAdmin' });
      }
    },
    close() {
      if(this.org.expireDays != null && this.org.expireDays == 0) {
        if (this.org.id != this.$store.getters.homeProfile) {
          this.$router.back();
        } else {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('userSession');
          this.$router.replace({ name: 'Login' });
        }
      }
      
      this.$emit('onHide');
    },
  }
});
