




























































import { UUID } from '@/models/Types';
import api from '@/api';
import Vue from 'vue';
import JobAbstract from '@/models/JobAbstract';
// components
import AdAgingBar from '@/components/boards/AdAgingBar.vue';

export default Vue.extend({
  name: 'JobCard',
  components: {
    AdAgingBar,
  },
  props: {
    job: { type: Object as () => JobAbstract, required: true },
    dupDisabled: { type: Boolean, required: true }
  },
  computed: {
    isDisabled() {
        return false;
    },
    isRightAd(): boolean {
      return (this.job.status & 2) != 0;
    },
    counter(): number {
      let cnt = 0;
      this.job.candidateStats.forEach((s) => (cnt += s.count));
      return cnt;
    },
  },
  methods: {
    async duplicate() {
      
      try {
        const rslt = await this.$http.get(`${api}/jobs/${this.job.id}/duplicate`, { headers: { Accept: 'application/json' } });
        var pid = rslt.data as UUID;

        // navigate to new org's board
        this.$router.push(`/jobs/${pid}/edit/`);

      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
        return null;
      }
    }
  }
});
