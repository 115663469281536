



































































import Vue from 'vue';
import CompanyAbstract from '@/models/CompanyAbstract';
import { ProfilePermissions } from '@/models/Permissions';
import { EffectivePrivileges } from '@/models/Privileges';
// components
import ClientCard from '@/components/boards/ClientCard.vue';
import MaterialInput from '@/components/MaterialInput.vue';

interface Data {
    filter: string;
}

export default Vue.extend({
    name: 'ClientSidebar',
    components: {
        MaterialInput,
        ClientCard
    },
    props: {
        clients: { type: Array as () => CompanyAbstract[], required: true },
        privileges: { type: Object as () => EffectivePrivileges, required: true },
        isLoading: { type: Boolean, required: true }
    },
    data(): Data {
        return {
            filter: ''
        }
    },
    computed: {
        isDisabled(): boolean {
            return (this.privileges.p & ProfilePermissions.View) == 0;
        },
        showToggle(): boolean {
            return (this.privileges.p & ProfilePermissions.ToggleLimit) == ProfilePermissions.ToggleLimit;
        },
        activesClients(): CompanyAbstract[] {
            return this.clients.filter(c => c.isActive);
        },
        inactiveClients(): CompanyAbstract[] {
            return this.clients.filter(c => !c.isActive);
        },
        filteredSet(): CompanyAbstract[] {
            return this.activesClients.filter(c => c.companyName.toUpperCase().includes(this.filter.toUpperCase()));
        },
        isWerkberry(): boolean {
            return this.$store.getters.isWerkberry;
        }
    }
});
