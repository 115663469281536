



















import Vue from 'vue';
import api from '@/api';
import ClientAbstract from '@/models/ClientAbstract';

export default Vue.extend({
    name: 'ClientCard',
    props: {
        client: { type: Object as () => ClientAbstract, required: true },
        showToggle: { type: Boolean, required: true }
    },
    data(){
        return {
            toggleLink: ''
        }
    },
    methods: {
        async toggle() {
            try{
                const rslt = await this.$http.put(`${api}/profiles/${this.client.id}/toggle-type`);
                this.client.isLimited = !this.client.isLimited;
                this.toggleLink = this.client.isLimited ? 'Limited' : 'Unlimited';
            } catch (error) {
                // eslint-disable-next-line
                console.error(error);
            }
        }
    },
    mounted() {
        this.toggleLink = this.client.isLimited ? 'Limited' : 'Unlimited';
    }
});
