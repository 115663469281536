



























import Vue from 'vue';
import JobAbstract from '@/models/JobAbstract';
//components
import JobCard from '@/components/boards/JobCard.vue';

interface Data {
    isLoading: boolean;
    cards: JobAbstract[];
}

export default Vue.extend({
    name: 'JobCardList',
    components: {
        JobCard
    },
    data(): Data {
        return {
            isLoading: false,
            cards: []
        };
    },
    props: {
        title: { type: String, required: false, default: 'Jobs'},
        endpoint: { type: String, required: false },
        nameFilter: { type: String, required: true },
        categoryFilter: { type: String, required: false, default: '' },
        duplicationDisabled: { type: Boolean, required: true }
    },
    computed: {
        filteredSet(): JobAbstract[] {

            let filtered = [...this.cards.filter(j => j.title.toUpperCase().includes(this.nameFilter.toUpperCase()))]

            switch (this.categoryFilter) {
                case 'Open: RightAd':
                    return filtered.filter(j => (j.status & 2) == 2);
                case 'Open: Career Page':
                    return filtered.filter(j => (j.status & 2) == 0);
                case 'New Candidates':
                    return filtered.filter(j => j.candidateStats.filter(s => s.status == 0).length > 0)
                                   .sort((a, b) => a.candidateStats[0].count > b.candidateStats[0].count 
                                                ? -1
                                                : a.candidateStats[0].count < b.candidateStats[0].count ? 1 : 0);
                case 'Phone Screens':
                    return filtered.filter(j => j.candidateStats.filter(s => s.status == 18).length > 0)
                                   .sort((a, b) => a.candidateStats[0].count > b.candidateStats[0].count 
                                                ? -1 
                                                : a.candidateStats[0].count < b.candidateStats[0].count ? 1 : 0);
                case 'Interviews':
                    return filtered.filter(j => j.candidateStats.filter(s => s.status == 20).length > 0)
                                   .sort((a, b) => a.candidateStats[0].count > b.candidateStats[0].count 
                                                ? -1 
                                                : a.candidateStats[0].count < b.candidateStats[0].count ? 1 : 0);
                case 'Offers':
                    return filtered.filter(j => j.candidateStats.filter(s => s.status == 32).length > 0)
                                   .sort((a, b) => a.candidateStats[0].count > b.candidateStats[0].count 
                                                ? -1 
                                                : a.candidateStats[0].count < b.candidateStats[0].count ? 1 : 0);
                case 'Candidate Count':
                    return filtered.sort((a, b) => {
                                let cnta = 0;
                                let cntb = 0;
                                a.candidateStats.forEach(s => cnta += s.count);
                                b.candidateStats.forEach(s => cntb += s.count);
                                return cnta > cntb ? -1 : cnta < cntb ? 1 : 0;
                           });
                default:
                    return filtered;
            }
        },
        count(): string {
            const len = this.filteredSet.length;
            return len != this.cards.length ? `${len} of ${this.cards.length}` : `${this.cards.length}`;
        }
    },
    async mounted() {

        try {
            this.isLoading = true;
            const rslt = await this.$http.get(this.endpoint);
            this.cards = rslt.data;

            this.$emit("onLoad", this.cards);

        } catch (error) {
            // eslint-disable-next-line
            console.error(error);
        } finally {
            this.isLoading = false;
        }
    }
})
