



















































































































import { UUID } from '@/models/Types'
import Vue from "vue";
import api from '@/api';
import CompanyAbstract from "@/models/CompanyAbstract";
import Invite, { DefaultInvite } from '@/models/Invite';
import { SystemPermissions } from '@/models/Permissions';
import { PrivilegeRequest } from "@/models/Privileges";
import Role from '@/models/Role';
// components
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSelect from "@/components/MaterialSelect.vue";
// validation
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";


extend("required", {
    ...required,
    message: "{_field_} is required",
});

extend("email", {
    ...email,
    message: "{_field_} must be a valid email",
});

interface Data {
    invite: Invite; 
    roles: Role[];
    selectedRole: number;
    // isDelegatedPayee: boolean;
    isInvalid: boolean;
    isDiscreetRole: boolean
}

export default Vue.extend({
    name: "ClientInviteModal",
    components: {
        MaterialInput,
        MaterialSelect,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        org: { type: Object as () => CompanyAbstract, required: true },
        isNewClient: { type: Boolean, required: false, default: false },
        isPayAdmin: { type: Boolean, required: false, default: false },
    },
    data(): Data {
        return {
            invite: { ...DefaultInvite, sponsorId: this.org.id, sponsorOrg: this.org.companyName, isNewClient: this.isNewClient },
            roles: [],
            selectedRole: -1,

            isInvalid: false,
            isDiscreetRole: false
        };
    },
    computed: {
        isLoading(): boolean {
            return this.$store.getters.isLoading;
        },
        currentOrg(): string {
            return this.org.id;
        },
        notReady(): boolean {
            return this.invite.role == null;
        },
        viewWBAdminControls(): boolean {
            return (this.$store.getters.sysPrivileges & SystemPermissions.Administrator) == SystemPermissions.Administrator;
        },
        viewWBNewClientControls(): boolean {
            return this.isNewClient && this.viewWBAdminControls;
        },
        filteredDescriptions(): string[] {
            const vals: string[] = [];
            if(this.selectedRole == -1) {
                return vals;
            }

            this.isDiscreetRole 
                    ? this.roles[this.selectedRole].description.filter(d => d.item2).forEach(d => vals.push(d.item1))
                    : this.roles[this.selectedRole].description.forEach(d => vals.push(d.item1));

            return vals;
        }
    },
    watch: {
        currentOrg(pid: string) {
            this.reset(pid, this.org.companyName);
        }
    },
    methods: {
        reset(pid: UUID, name: string) {

            this.invite = { 
                 sponsorId: pid, sponsorOrg: name, clientName: null,
                 recipient: null, firstName: null, lastName: null, role: null,
                 isNewClient: this.isNewClient,
                 isUnlimited: false,
                 isChannel: false,
                 isInvoiced: false
            };

            this.selectedRole = -1;
        },
        setRole(role: string) {

            if(role == '-1') {
                this.selectedRole = -1
                this.invite.role = null;
            } else {
                try {
                    const itm: Role = this.roles.find(i => i.name == role)!;
                    this.selectedRole = itm.id;

                    // assign selected role
                    const req: PrivilegeRequest = {
                        name: itm.name,
                        efp: itm.efp, 
                        s: itm.name == 'Werkberry Admin' ? SystemPermissions.Viewer | SystemPermissions.Administrator : itm.name == 'Werkberry User' ? SystemPermissions.Viewer : SystemPermissions.None,
                        exclusive: this.isDiscreetRole
                    };

                    this.invite.role = req;

                } catch (error) {
                    // eslint-disable-next-line
                    console.error(`role ${role} not found`);
                    this.selectedRole = -1;
                    this.invite.role = null;
                }
            }
        },
        async sendInvite() {
            try {
                this.$store.commit('setIsLoading', true);
                // post to account invitation endpoint
                await this.$http.post(`${api}/request/invite`, this.invite);
            } catch (error) {
                this.isInvalid = true;
                await new Promise(r => setTimeout(r, 2500));
                // eslint-disable-next-line
                console.error(error);
            } finally {
                this.$store.commit('setIsLoading', false);
                this.close();
            }
        },
        close() {
            this.reset(this.org.id, this.org.companyName);
            this.$emit("onHide");
        }
    },
    async mounted() {

        try {

            this.roles = this.isNewClient ? this.$store.getters.newRoles : this.$store.getters.allRoles;

            if(this.roles.length == 0) {
                const rslt = await this.$http.get(`${api}/invite/roles?isNew=${this.isNewClient}`);
                this.roles = rslt.data;
                this.$store.commit('setRoles', this.roles);
            }

            if(this.isPayAdmin) {
                this.setRole('Admin with Payment Authorization');
            }

        } catch (error) {
            // eslint-disable-next-line
            console.error(error);
        }
    }
});
